<template>
  <div class="contact_us-page container-fluid">
    <div class="container">
      <div class="inner-box">
        <div class="row">
          <div class="col-md-10 col-sm-10 left-side">
            <div class="left-info-box">
              <div class="title">
                <span>{{ $t('contact_page.title') }}</span>
              </div>
              <div class="email_box">
                <div v-if="emailError" class="mt-1 text-danger">
                  {{ errors.email }}
                </div>
                <input
                  class="mt-3 pb-3"
                  type="email"
                  :class="{ error: emailError }"
                  placeholder="Enter your email"
                  v-model="email"
                  required
                />
              </div>
              <div class="textarea-box mt-4">
                <div v-if="textError" class="mt-1 pb-2 text-danger">
                  {{ errors.text }}
                </div>
                <textarea
                  cols="30"
                  rows="10"
                  class="textarea-box_text"
                  :class="{ error: textError }"
                  v-model="textPlain"
                  placeholder="Type your message here..."
                ></textarea>
              </div>
              <div class="button-box">
                <button class="stay" @click="sendData">
                  {{ $t('contact_page.stay') }}
                </button>
              </div>
            </div>
            <div class="right-info-box">
              <div class="item">
                <div class="title">
                  <span>{{ $t('contact_page.touch_us') }}</span>
                </div>
                <div class="description">
                  <span>{{ $t('contact_page.any_questions') }} </span>
                </div>
              </div>
              <div class="item">
                <div class="low-title">
                  <span>{{ $t('contact_page.address') }}</span>
                </div>
                <div class="description">
                  <span>2715 Ash Dr. San Jose, South Dakota 83475</span>
                </div>
              </div>
              <div class="item">
                <div class="low-title">
                  <span>{{ $t('contact_page.contact') }}</span>
                </div>
                <div class="description">
                  <span class="number">(406) 555-0120</span>
                  <span class="email">alma.lawson@example.com</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2 col-sm-2 right-side">
            <div class="menu-item">
              <div class="menu__nav-menu">
                <nav class="nav-menu">
                  <ul class="menu-item">
                    <li>{{ $t('contact_page.menu') }}</li>
                    <li>{{ $t('contact_page.features') }}</li>
                    <li>{{ $t('contact_page.account') }}</li>
                    <li>{{ $t('contact_page.contact') }}</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cards container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-4 cards_accept">
            <div class="cards__title">
              <span>{{ $t('contact_page.accept') }}</span>
            </div>
          </div>
          <div class="col-md-8 col-sm-8 cards__list">
            <div class="skrill">
              <img src="~@assets/img/skrill.png" alt="skrill" />
            </div>
            <div class="visa">
              <img src="~@assets/img/visa.png" alt="visa" />
            </div>
            <div class="mc">
              <img src="~@assets/img/mc.png" alt="master_card" />
            </div>
            <div class="neteller">
              <img src="~@assets/img/neteller.png" alt="neteller" />
            </div>
            <div class="wire">
              <img src="~@assets/img/wire.png" alt="wire" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { MAIL_MUTATION_TYPES } from '../../../store/modules/mail'
export default {
  name: 'contact-us',
  data: () => {
    return {
      errors: {
        text: '',
        email: '',
      },
    }
  },
  computed: {
    ...mapState('Mail', ['mail', 'text']),
    textError() {
      return this.errors.text.length
    },
    emailError() {
      return this.errors.email.length
    },
    email: {
      get() {
        return this.mail
      },
      set(value) {
        return this.SET_MAIL(value)
      },
    },
    textPlain: {
      get() {
        return this.text
      },
      set(value) {
        return this.SET_TEXT(value)
      },
    },
  },
  methods: {
    ...mapMutations('Mail', [
      MAIL_MUTATION_TYPES.SET_MAIL,
      MAIL_MUTATION_TYPES.SET_TEXT,
    ]),
    ...mapActions('Mail', ['sendMail']),
    sendData() {
      if (!this.email.length) {
        this.errors = {
          ...this.errors,
          email: 'Email must not be empty',
          text: '',
        }
        return
      } else {
        this.errors = {
          ...this.errors,
          email: '',
          text: '',
        }
      }
      this.sendMail()
        .then(() => {
          this.errors = { text: '', email: '' }
        })
        .catch(({ message }) => {
          this.errors = {
            ...this.errors,
            ...message.reduce((acc, msg) => {
              if (!msg.indexOf('email')) {
                return { ...acc, email: msg }
              }
              return { ...acc, text: msg }
            }, {}),
          }
        })
    },
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
